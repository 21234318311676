.Aloha-div1 {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
}

.Aloha-div2 {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
}

.Background-Color-Blue {
    background-color: #3a367e;
}

.Background-Color-Green {
    background-color: #92c85d;
}

.Text-Blue {
    color: #3a367e;
}

.Background-Color-Green-Blue {
    background-image: linear-gradient(#3a367e, #92c85d)
}